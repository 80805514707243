<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(deposit).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ยอดฝากทั้งหมด"
          class="mb-2"
          v-loading="loadingCard"
        >
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-money-coins text-success"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(withdraw).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ยอดถอนทั้งหมด"
          class="mb-2"
          v-loading="loadingCard"
        >
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-money-coins text-danger"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(bonus).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ฝากโบนัส"
          class="mb-2"
          v-loading="loadingCard"
        >
          <div slot="header" class="icon-info">
            <i class="nc-icon nc-money-coins text-info"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(cancel).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ดึงคืนด่วน"
          class="mb-2"
          v-loading="loadingCard"
        >
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-refresh-02 text-danger"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          :title="
            Number(income).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="กำไร/ขาดทุน"
          class="mb-2"
          v-loading="loadingCard"
        >
          <div slot="header" class="icon-primary">
            <i class="nc-icon nc-money-coins text-primary"></i>
          </div>
        </stats-card>
      </div>
    </div>

    <card>
      <div class="row">
        <div class="col-sm-2 d-inline-block">
          <el-select
            class="select-default mb-3 w-100"
            v-model="pagination.perPage"
            placeholder="Per page"
            @change="getDashboardAllByPhone()"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>

        <div class="col-sm-3 ml-auto text-right">
          <el-button
            type="danger"
            size="default"
            @click="getDashboardAllByPhone()"
            :loading="loading"
          >
            <i class="fa fa-refresh" aria-hidden="true"></i>
          </el-button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table
            stripe
            style="width: 100%; text-align: center"
            :data="tableData"
            border
            v-loading="loading"
          >
            <el-table-column :min-width="120" label="เวลาทำรายการ">
              <template v-slot="props">
                <span v-html="props ? props.row.D13 : ''"> </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="วัน/เวลา">
              <template v-slot="props">
                <span v-html="props ? props.row.D1 : ''"></span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="ประเภท">
              <template v-slot="props">
                <span v-html="props ? props.row.D2 : ''"></span>
              </template>
            </el-table-column>
            <el-table-column :min-width="120" label="Username">
              <template v-slot="props">
                <span v-html="props ? props.row.D3 : ''"></span>
              </template>
            </el-table-column>
            <!-- <el-table-column :min-width="100" label="Userbetflix">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"></span>
                  </template>
                </el-table-column> -->
            <el-table-column :min-width="120" label="ธนาคาร">
              <template v-slot="props">
                <span v-html="props ? props.row.D5 : ''"></span>
              </template>
            </el-table-column>
            <el-table-column :min-width="80" label="จำนวน">
              <template v-slot="props">
                <span v-html="props ? props.row.D6 : ''"></span>
              </template>
            </el-table-column>
            <el-table-column :min-width="80" label="โบนัส">
              <template v-slot="props">
                <span v-html="props ? props.row.D7 : ''"></span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="จำนวนก่อน">
              <template v-slot="props">
                <span v-html="props ? props.row.D8 : ''"></span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="จำนวนหลัง">
              <template v-slot="props">
                <span v-html="props ? props.row.D9 : ''"></span>
              </template>
            </el-table-column>
            <el-table-column :min-width="150" label="รายละเอียด">
              <template v-slot="props">
                <span v-if="props.row.type === '0' && props.row.way === null">
                  <button
                    class="btn btn-info btn-sm"
                    @click="qrshow(props ? props.row.D12 : '')"
                  >
                    <i class="fa fa-qrcode" aria-hidden="true"></i>
                  </button>
                </span>
                <span
                  v-else-if="props.row.type === '0' && props.row.way === 'AUTO'"
                  class="badge badge-warning"
                >
                  {{ props.row.D12 }}
                </span>
                <span
                  v-else-if="props.row.D12 === '9'"
                  class="badge badge-danger"
                >
                  แอดมินถอนมือ
                </span>
                <span v-else>
                  {{ props.row.D12 }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="180" label="ผู้ทำรายการ">
              <template v-slot="props">
                <span v-html="props ? props.row.D11 : ''"></span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <l-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total"
        >
        </l-pagination>
      </div>
    </card>
  </div>
</template>
<script>
import {
  DatePicker,
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Dialog,
} from "element-ui";
import {
  StatsCard,
  Pagination as LPagination,
  FormGroupInput as FgInput,
} from "src/components/index";
import axios from "axios";
import moment from "moment";
import qrcode from "vue-qrcode-component";
import Swal from "sweetalert2";

export default {
  props: ["phone"],
  components: {
    [Button.name]: Button,
    [DatePicker.name]: DatePicker,
    LPagination,
    StatsCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0,
      },
      tableData: [],

      deposit: 0,
      income: 0,
      bonus: 0,
      withdraw: 0,
      cancel: 0,

      loading: false,
      loadingCard: false,
    };
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      this.getDashboardAllByPhone();
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  watch: {
    phone(newVal, oldVal) {
      this.bonus = 0;
      this.deposit = 0;
      this.withdraw = 0;
      this.income = 0;
      this.cancel = 0;
      this.tableData = [];
      this.historyMember();
    },
  },
  mounted() {
    this.historyMember();
  },
  methods: {
    async historyMember() {
      let data = JSON.stringify({
        phone: this.phone,
      });

      this.loading = true;
      this.loadingCard = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/historymember`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          let data = res.data;
          this.loading = false;
          this.loadingCard = false;
          if (data.res_code === "00") {
            data = data.res_result[0];

            // if (!data.deposit && !data.withdraw && !data.bonus) {
            //   return Swal.fire("แจ้งเตือน", "ไม่พบรายการฝาก", "error");
            // }

            this.bonus = data.bonus ? data.bonus : 0;
            this.deposit = data.deposit ? data.deposit : 0;
            this.withdraw = data.withdraw ? data.withdraw : 0;
            this.cancel = data.cancel ? data.cancel : 0;
            this.income =
              data.deposit - data.withdraw - data.bonus - data.cancel;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.loadingCard = false;
        });
    },
    async getDashboardAllByPhone() {
      let offset = this.pagination.perPage * (this.pagination.currentPage - 1);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/transactions?search=${this.phone}&sort&order=desc&offset=${offset}&limit=${this.pagination.perPage}&action&start_date=&end_date=`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
      };

      this.loading = true;

      await axios
        .request(config)
        .then((res) => {
          let data = res.data;
          if (data.res_code === "00") {
            this.tableData = data.res_result.rows;
            this.pagination.total = data.res_result.total;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang=""></style>
